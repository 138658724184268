.slick-prev:before, .slick-next:before {
  color: black !important;
}

.slide {
    height: 100%;
}

.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
    padding: 0 5px;
}

.slick-list {
    margin: 0 2em;
}

.text-alert {
    animation: blink-animation 1s infinite;
}

.text-normal {
    color: green
}
  
@keyframes blink-animation {
0% {
    opacity: 1;
    color: red;
}
50% {
    opacity: 1;
    color: #FF7F50;
}
100% {
    opacity: 1;
    color: red;
}
}