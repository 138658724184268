.float-label {
    position: relative;
    margin-bottom: 12px;
}

.floating {
    .label {
        font-size: 12px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 12px;
        top: 12px;
        transition: 0.2s ease all;
    }
      
    .label-float {
        top: 6px;
        font-size: 10px;
    }
    
    .ant-select .ant-select-selector {
        padding: 16px 10px 4px 11px;
    }
      
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 16px 10px 4px 11px;
        height: 48px;
    }
      
    .ant-select-selection-search {
        top: 16px;
    }
}
