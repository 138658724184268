body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mainContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.boarder {
  border: 1px solid black;
  border-collapse: collapse;
}

.scollbar::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.scollbar::-webkit-scrollbar-thumb {
  background: #C0C0C0;
  border-radius: 4px;
}
