.streamheader {
    background-color: grey;
    color: white;
    padding: 5px;
    display: flex;
    flex-flow: row;
}
  
.streamTitle {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
}
  
.streamWindow {
    width: 100%;
    height: 100%;
    pointer-events: none;
}
  
.windowContainer {
    display: flex;
    flex-flow: column;
    border: 1px solid black;
    border-collapse: collapse;
}
  
.windowContainerIsOver {
    display: flex;
    flex-flow: column;
    border: 1px solid blue;
    border-collapse: collapse;
}